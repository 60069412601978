<template>
  <div>
    <span class="viewH">Документы</span>
    <hr>
    <div class="doc-pass">
      <v-text-field
        class="doc-pass-input"
        label="Пароль для доступа к файлам"
        dense
        outlined
        v-model="pass"
        :disabled="!passVis"
      ></v-text-field>
      <div class="doc-pass-btn">
        <v-btn :disabled="pass" @click="getPass" v-if="!passVis" color="primary">показать</v-btn>
        <v-btn :disabled="!pass" @click="passVis = true" v-if="!passVis" color="#FF4500">изменить</v-btn>
        <v-btn v-if="passVis" @click="passUpd" color="green">сохранить</v-btn>
        <v-btn @click="passVis = false" v-if="passVis" color="red">отмена</v-btn>
      </div>
    </div>
    <div class="doc-table">
      <v-simple-table dark>
        <template v-slot:default>
          <tbody>
            <tr>
              <td style="width: 30px;">
                <v-btn text x-small color="green" @click="doc.dialogDoc = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </td>
              <td colspan="2">Методические материалы</td>
            </tr>
            <tr style="text-align: center;">
              <td colspan="2" style="border-right: solid 1px #2b2b2b;">Имя файла</td>
              <td>Имя файла на сервере</td>
            </tr>
            <tr v-for="(item, i) in getDoc" :key="`doc${i}`">
              <td>
                <v-btn text x-small color="red" @click="delDoc(item.id)">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </td>
              <td>{{`${item.name} (${item.filename.split('.')[item.filename.split('.').length - 1]})`}}</td>
              <td>{{item.filename}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-dialog
        v-model="doc.dialogDoc"
        persistent
        max-width="370"
      >
        <v-card>
          <div class="ins-doc-content">
            <v-form ref="form">
              <v-text-field
                :label="'Введите название документа'"
                outlined
                dense
                v-model="doc.docname"
                :rules="docNameRules"
              ></v-text-field>
              <v-file-input
                label="Выберите файл"
                counter
                show-size
                truncate-length="15"
                outlined
                dense
                v-model="doc.docfile"
                :rules="docFileRules"
              ></v-file-input>
            </v-form>
            <div class="ins-doc-btn">
              <v-btn small color="red" @click="cancelDoc">отмена</v-btn>
              <v-btn small color="green" @click="savDoc">сохранить</v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  props: {
    messageOn: Function,
    loadingWindow: Function
  },
  data: () => ({
    doc: {
      dialogDoc: false,
      docname: null,
      docfile: null
    },
    passVis: false,
    passID: null,
    pass: null,
    docNameRules: [
      v => !!v || 'Имя не должно быть пустым!'
    ],
    docFileRules: [
      v => !!v || 'Выберите файл!'
    ],
  }),
  computed: {
    ...mapGetters(['getDoc'])
  },
  methods: {
    ...mapActions(['docpass', 'docPassUpdate', 'uploadDoc', 'deleteDoc']),
    async getPass() {
      let result = await this.docpass()
      this.pass = result[0].pass
    },
    async passUpd() {
      await this.docPassUpdate({
        pass: this.pass.trim()
      })
      let result = await this.docpass()
      this.pass = result[0].pass
      this.messageOn('Пароль изменен', 'green')
      this.passVis = false
    },
    async savDoc() {
      if (this.$refs.form.validate()) {
        this.loadingWindow = true
        let response = await this.uploadDoc({
          file: this.doc.docfile,
          name: this.doc.docname
        })
        if (response.uploadDoc == true) {
          this.loadingWindow = false
          this.doc.dialogDoc = false
          this.$refs.form.reset()
          this.messageOn('Документ загружен', 'green')
        }
        if (response.uploadDoc == false) {
          this.loadingWindow = false
          this.doc.dialogDoc = false
          this.$refs.form.reset()
          this.messageOn('[Ошибка:] Документ не загружен', 'red')
        }
      }
    },
    async delDoc(id) {
      this.loadingWindow(true)
      let res = await this.deleteDoc({id})
      this.loadingWindow(false)
      if(res.deleteDoc == 'ok') {
        this.messageOn('Документ удален', 'green')
      } else {
        this.messageOn('[Ошибка:] Документ не удален', 'red')
      }
    },
    cancelDoc() {
      this.doc.dialogDoc = false
      this.$refs.form.reset()
      this.doc = {}
    }
  }
}
</script>

<style lang="scss" scoped>
  .doc-pass {
    margin: 25px 0;
    display: flex;
    .doc-pass-input {
      max-width: 350px;
    }
    .doc-pass-btn {
      margin: 1px 0 0 7px;
      button {
        margin-right: 7px;
      }
    }
  }
  .ins-doc-content {
    padding: 15px;
    .ins-doc-btn {
      text-align: center;
      margin: 15px 0 7px;
      button {
        margin: 0 5px;
      }
    }
  }
</style>
